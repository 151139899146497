import React from 'react';
import styled from 'styled-components';
import { WebsiteDefaultPage } from '../components/Page';
import {Color, Fonts, globalH2Default, mediaQueries} from '../styles';
import { Link } from '../components/Gui/Links';
import Footer from '../components/Global/Footer';

const Wrapper = styled.div`
  margin: 134px auto 244px;
  max-width: 100%;
  ${mediaQueries('xl')`
    max-width: 64%;
    margin: 134px auto 244px 0;
  `}; 
  
  a {
    font-size: 1.4rem;
    color: ${Color.BLACK};
    ${Fonts.POPPINS};
    font-weight: 500;
  }
  
  p {
      margin-bottom: 32px;  
      
      &:nth-child(3) {
      max-width: 95%;
          ${mediaQueries('md')`
            max-width: 100%;
          `};
      }
      
      &:nth-child(5) {
      max-width: 99%;
          ${mediaQueries('md')`
            max-width: 100%;
          `};
      }
      
      &:nth-child(6) {
      max-width: 97%;
          ${mediaQueries('md')`
            max-width: 100%;
          `};
      }
      &:nth-child(7) {
          ${mediaQueries('xl')`
            max-width: 96%;
          `};
      }
  }
`;

const Heading = styled.h1`
  ${globalH2Default};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  padding-bottom: 32px;

  .heading-image {
    flex: 0 0 auto;
    margin-left: 24px;
  }

  ${mediaQueries('md')`
      font-size: 7.48rem;
      line-height: calc(65/75);
      letter-spacing: calc(-0.68em/75);
      padding-bottom: 48px;
    `};
    
    ${mediaQueries('xl')`
      padding-bottom: 64px;
    `};  
`;

const About = () => {
    return (
        <WebsiteDefaultPage bodyBackground={Color.GREY_ONE}>
            <Wrapper>
                <Heading>About</Heading>
                <p>
                    Mike Cagney is a boundary-pushing and innovative entrepreneur with decades of experience in the financial industry. He has a passion for building companies, particularly in the financial services and financial technology industries, and has been fortunate enough to intersect both in many of his endeavors.
                </p>
                <p>
                    Most recently is his work with <Link url={`https://www.figure.com`}>Figure Technologies</Link>, a fintech company that leverages blockchain, artificial intelligence, and advanced analytics to transform home equity, mortgage refinancing, student and personal loan approvals, and more. Mike co-founded Figure with his wife, June Ou, in 2018. He serves as the company's CEO, leading their corporate strategy and development.
                </p>
                <p>
                    Under Mike Cagney's leadership, Figure Technologies raised over $225 million in a funding round in 2019 and by 2020, the company had funded loans in excess of $1 billion. Figure has helped over 50,000 households across the United States by unlocking over $3 billion for its members, a number that continues to grow. As such, Figure Technologies has been recognized by institutions like Forbes, appearing on their Blockchain 50 and America's Best Startup Employers lists, as well as earning a spot in The Tech Tribune's 2021 mentions for Best Tech Startups.
                </p>
                <p>
                    Mike Cagney has also been recognized for his business acumen. He was named to <Link url={`https://www.businessinsider.com/bi-100-the-creators-business-visionaries-creating-value-for-the-world-2016-6`}>Business
                    Insider's Creators list</Link> of the top 100 business visionaries who are creating value for the world. He's also been featured in a number of publications, like <Link url={`https://www.fastcompany.com/person/mike-cagney`}>Fast Company</Link>, and has contributed his ideas to outlets like <Link url={`https://www.huffpost.com/author/mike-cagney`}>Huffington Post</Link>. Mike has also spoken at a few conferences as well. In 2016, he was a speaker at The Future of Fintech conference, which centered around discussions about emerging financial services innovations. Then in 2017, he spoke at TechCrunch Disrupt NY, which was one of the most anticipated startup and thought leadership forums that year.
                </p>
                <p>
                    Before Figure Technologies, Mike Cagney was involved with various companies in different capacities. After graduating from University of California Santa Cruz with a M.S. in Applied Economics and a B.A. in Economics, Mike started his career as an analyst at Wells Fargo before leaving in 2000 to start Finaplex, a wealth management software company. He later co-founded Cabezon Investment Group, a global macro hedge fund, before receiving a Sloan Fellowship at Stanford University's Graduate School of Business in 2010. It was during this time that Mike Cageny and four of his fellow graduate students created Social Finance (SoFi), where Mike served as CEO for just over six years before founding Figure Technologies.
                </p>
                    <p>
                    During his time at UC Santa Cruz, Mike was considered a prominent Sloan Fellow alumni. The Sloan Fellowship program that helped him launch his career is not an opportunity that's available to all UC students, but to those that have been pinpointed as early-career scientists and scholars with outstanding promise.
                    </p>
                    <p>
                    Outside of his professional endeavors, Mike Cagney's other interests include kayak fishing, wine, cycling, and weight lifting. Health and fitness are very important to him and even set the bar for his productivity and focus in his work life. Mike also uses his personal time to keep a finger on the pulse of everything that's going on in the world of blockchain technology.
                </p>
            </Wrapper>
            <Footer />
        </WebsiteDefaultPage>
    );
};

export default About;
